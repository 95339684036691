// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import NavDropdown from "./kz_o5zftw";
const NavDropdownFonts = getFonts(NavDropdown);

const enabledGestures = {YD4lPCULA: {hover: true}};

const cycleOrder = ["YD4lPCULA", "sRlC3mUIq"];

const serializationHash = "framer-Y4aJC"

const variantClassNames = {sRlC3mUIq: "framer-v-1rzgwjw", YD4lPCULA: "framer-v-zlhyio"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 0.5, rotate: 0, rotateX: 0, rotateY: 0, scale: 1.05, skewX: 0, skewY: 0, transition: transition2}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "YD4lPCULA", "Variant 2": "sRlC3mUIq"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "YD4lPCULA"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "YD4lPCULA", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zlhyio", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"YD4lPCULA"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"YD4lPCULA-hover": {"data-framer-name": undefined}, sRlC3mUIq: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7Um9ib3RvLXJlZ3VsYXI=", "--framer-font-family": "\"Roboto\", \"Roboto Placeholder\", sans-serif", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(24, 24, 24))"}}>Services</motion.p></React.Fragment>} className={"framer-196dhwt"} data-framer-name={"Services"} fonts={["GF;Roboto-regular"]} layoutDependency={layoutDependency} layoutId={"xGZqcESWx"} style={{"--extracted-r6o4lv": "rgb(24, 24, 24)", "--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} whileHover={animation} withExternalLayout/><ComponentViewportProvider width={"16px"}><motion.div className={"framer-sef96v-container"} layoutDependency={layoutDependency} layoutId={"ZrHVAq_vW-container"} whileHover={animation}><NavDropdown height={"100%"} id={"ZrHVAq_vW"} layoutId={"ZrHVAq_vW"} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Y4aJC.framer-11xlgga, .framer-Y4aJC .framer-11xlgga { display: block; }", ".framer-Y4aJC.framer-zlhyio { cursor: pointer; height: 20px; overflow: hidden; position: relative; width: 82px; }", ".framer-Y4aJC .framer-196dhwt { flex: none; height: auto; left: 0px; mix-blend-mode: difference; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-Y4aJC .framer-sef96v-container { flex: none; height: 17px; position: absolute; right: 0px; top: calc(50.00000000000002% - 17px / 2); width: 16px; }", ".framer-Y4aJC.framer-v-1rzgwjw.framer-zlhyio { cursor: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 82
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"sRlC3mUIq":{"layout":["fixed","fixed"]},"LGVprZ5wu":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerP0SvuPXi6: React.ComponentType<Props> = withCSS(Component, css, "framer-Y4aJC") as typeof Component;
export default FramerP0SvuPXi6;

FramerP0SvuPXi6.displayName = "navigation dropdown";

FramerP0SvuPXi6.defaultProps = {height: 20, width: 82};

addPropertyControls(FramerP0SvuPXi6, {variant: {options: ["YD4lPCULA", "sRlC3mUIq"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerP0SvuPXi6, [{explicitInter: true, fonts: [{family: "Roboto", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me4GZLCzYlKw.woff2", weight: "400"}]}, ...NavDropdownFonts], {supportsExplicitInterCodegen: true})